<template>
  <div>
    <Translation/>
  </div>
</template>

<script>
import Translation from '@/components/Translation'

export default {
  name: "NewTranslation",
  components: {
    Translation
  }
}
</script>

